import {
  PAYMENT_CHECKOUT_REQUEST,
  PAYMENT_CHECKOUT_SUCCESS,
  PAYMENT_CHECKOUT_FALIURE
} from './PaymentCheckoutConstants'
import {config} from '../../config'

export const paymentCheckoutRequest = (payment_checkout_info) =>{
  return{
    type : PAYMENT_CHECKOUT_REQUEST,
    payload : payment_checkout_info
  }
}

export const paymentCheckoutSuccess = (payment_checkout_success) =>{
  return{
    type : PAYMENT_CHECKOUT_SUCCESS,
    payload : payment_checkout_success
  }
}

export const paymentCheckoutFaliure = (error) =>{
  return{
    type : PAYMENT_CHECKOUT_FALIURE,
    payload : error
  }
}

export const paymentClearCheckout = (payment_checkout_info) =>{
  return(dispatch) => {
    dispatch(paymentCheckoutRequest(payment_checkout_info))
  }
}

export const paymentCheckout = (payment_checkout_info) =>{
    return(dispatch) => {
      dispatch(paymentCheckoutRequest(payment_checkout_info))
      const url =`${config.api_base}/users/business/order/payment/checkout?access_token=${payment_checkout_info.final_user_checkout_token}`;
      const request_option = {
      method: "POST",
      headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
            fields: {
              payByGiftCard: payment_checkout_info.giftcard_number && payment_checkout_info.giftcard_number != "" ? true : false,
              giftCard: payment_checkout_info.giftcard_number != "" ? [
              {
              card_number: payment_checkout_info.giftcard_number,
              pin: payment_checkout_info.giftcard_pin
              }
            ] : [],
              bucketId: payment_checkout_info.Unique_bucket_Id,
              cardToken: payment_checkout_info.payment_token,
              email: payment_checkout_info.email != undefined ? payment_checkout_info.email : "",
              gatewayId: payment_checkout_info.gatewayId,
              instrumentMode: "cc",
              notes:payment_checkout_info.notes_restaurant,
              orderDate: "",
              orderTime: "",
              paymentType: payment_checkout_info.payment_token == "authnettoken" ? "authnet" : "stripe",
              cardNumber : payment_checkout_info.cardNumber ? payment_checkout_info.cardNumber : '',
              expiryMonth : payment_checkout_info.expiryMonth ? payment_checkout_info.expiryMonth : '',
              expiryYear : payment_checkout_info.expiryYear ? payment_checkout_info.expiryYear : '',
              cvv : payment_checkout_info.cvv ? payment_checkout_info.cvv : '',
              orderDate:payment_checkout_info.orderDate ? payment_checkout_info.orderDate : '',
              orderTime:payment_checkout_info.orderTime ? payment_checkout_info.orderTime : ''
            },
            form_id: "",
            user_id: payment_checkout_info.final_user_checkout_email,
            process_centeralized_payment : payment_checkout_info.process_centeralized_payment


          })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(payment_checkout_res =>{
      const payment_checkout_success = payment_checkout_res
      dispatch(paymentCheckoutSuccess(payment_checkout_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(paymentCheckoutFaliure(errorMsg))
    })
    }
}
