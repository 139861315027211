export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/Tandoori-Flame-logo.png',
    banner_img_root : '/img/Tandoori-banner.png',
    resid : '',
    key_value : 'dtcoupouns',
    secret_value : 'dtcoupouns',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3079.928592108509!2d-104.87889208463257!3d39.47094187948617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c9b04e257a26f%3A0x5a0cf1526bda5403!2sTandoori%20Flames%20Indian%20Scratch%20Kitchen!5e0!3m2!1sen!2sin!4v1605693474046!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Tandoori Flames"
};
